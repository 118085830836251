import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Table from 'react-bootstrap/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { useDashboards } from 'api/hooks/use-dashboards'
import DeleteButton from './delete-button'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'

function List({ setDashboardId }) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleSelect = (id) => {
    navigate(`/dashboard/${id}`)
    setDashboardId(id)
    queryClient.invalidateQueries(['dashboard'])
    setShow(false)
  }
  const handleShow = () => setShow(true)
  const { isSuccess, data } = useDashboards({
    options: {
      enabled: show
    }
  })

  return (
    <React.Fragment>

      <span onClick={handleShow} className="form-text" style={{ cursor: 'pointer' }}>
        <FontAwesomeIcon icon={faCaretDown} />
      </span>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>All Views</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table size="sm">
            <thead>
              <tr>
                <th>Name</th>
                <th>Module</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {isSuccess &&
                data.map((d) => (
                  <tr key={`dashboard-view-${d.id}`}>
                    <td>
                      <Button
                        size="sm"
                        variant="link"
                        onClick={() => handleSelect(d.id)}
                      >
                        {d.name}
                      </Button>
                    </td>
                    <td>
                      <div className="mt-1">{d.section_name}</div>
                    </td>
                    <td>
                      <div className="float-end me-2">
                        <DeleteButton dashboardId={d.id} />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default List
