import React from 'react'
import useDashboardStats from 'api/hooks/use-dashboard-stats'
import StatCard from './stat-card'
import CenteredSpinner from 'shared/centered-spinner'
import { Col } from 'react-bootstrap'

function DashboardStats({
  params,
  setParams,
  sectionUnit,
  sectionName,
  isFilterOpen
}) {
  const queryParams = {
    dashboardId: params.dashboardId,
    section_id: params.section_id,
    time_period: params.time_period,
    risk_threshold: params.risk_threshold,
    minimum_amount: params.minimum_amount,
    maximum_amount: params.maximum_amount,
    vendors: params.vendors
      .filter((v) => v.options === 'include')
      .map((v) => v.id)
      .join('+'),
    excluded_vendors: params.vendors
      .filter((v) => v.options === 'exclude')
      .map((v) => v.id)
      .join('+'),
    employees: params.employees
      .filter((e) => e.options === 'include')
      .map((e) => e.id)
      .join('+'),
    excluded_employees: params.employees
      .filter((e) => e.options === 'exclude')
      .map((e) => e.id)
      .join('+'),
    analytics: params.analytics.map((a) => a.id).join('+'),
    org_ids: params.orgs.join('+'),
    object_ids: params.objects.join('+'),
    project_ids: params.projects.join('+'),
    seg_one_ids: params.segOnes.join('+'),
    seg_two_ids: params.segTwos.join('+'),
    seg_three_ids: params.segThrees.join('+'),
    seg_four_ids: params.segFours.join('+'),
    seg_five_ids: params.segFives.join('+'),
    seg_six_ids: params.segSixes.join('+'),
    seg_seven_ids: params.segSevens.join('+'),
    seg_eight_ids: params.segEights.join('+')
  }
  const options = {
    enabled: !isFilterOpen && params.section_id !== ''
  }
  const { isSuccess, isLoading, data } = useDashboardStats({
    params: queryParams,
    options
  })

  const getStatCard = () => {
    if (sectionName === 'Vendor') {
      return [
        {
          title: `# of ${sectionUnit}s`,
          value: data.invoice_count,
          dollarValue: false,
          total: data.total_transactions,
          percent: data.invoice_count_percent
        },
        {
          title: `# Risky ${sectionUnit}s`,
          value: data.risky_count,
          dollarValue: false,
          uncleared: parseFloat(data.uncleared_count)
        }
      ]
    } else {
      return [
        {
          title: `# of ${sectionUnit}s`,
          value: data.invoice_count,
          dollarValue: false,
          total: data.total_transactions,
          percent: data.invoice_count_percent
        },
        {
          title: `$ ${sectionUnit}s`,
          value: parseFloat(data.invoice_amount),
          dollarValue: true,
          total: data.total_amount,
          percent: data.invoice_amount_percent
        },
        {
          title: `# Risky ${sectionUnit}s`,
          value: data.risky_count,
          dollarValue: false,
          uncleared: parseFloat(data.uncleared_count)
        },
        {
          title: `$ Risky ${sectionUnit}s`,
          value: parseFloat(data.risky_amount),
          dollarValue: true,
          uncleared: parseFloat(data.uncleared_amount)
        }
      ]
    }
  }

  const stats = isSuccess ? getStatCard() : []

  return (
    <>
      {isLoading && <CenteredSpinner />}
      {isSuccess &&
        stats.map((stat, index) => (
          <Col key={`statcard-${index}`} sm="6" className="mb-4">
            <StatCard stat={stat} params={params} />
          </Col>
        ))}
    </>
  )
}

export default DashboardStats
