import React from 'react'
import PropTypes from 'prop-types'
import SortArrow from 'shared/tables/sort-arrow'

function TableHeader({ tableFilters, setTableFilters, columns, includeOrdering = true }) {
  const updateOrdering = (column) => {
    const isCurrentColumn = tableFilters.orderBy === column
    const columnOrder = getColumnOrder(isCurrentColumn)

    setTableFilters({
      ...tableFilters,
      orderBy: column,
      orderDirection: columnOrder,
      page: 1
    })
  }

  const getColumnOrder = (isCurrentColumn) => {
    if (isCurrentColumn) {
      return tableFilters.orderDirection === 'asc' ? 'desc' : 'asc'
    } else {
      return 'desc'
    }
  }

  return (
    <thead>
      <tr>
        {columns.includes('save') && <th></th>}
        {columns.includes('id') && (
          <th
            onClick={() => updateOrdering('id')}
            style={{ cursor: includeOrdering ? 'pointer' : 'default' }}
          >
            ID{' '}
            {includeOrdering && (
              <SortArrow
                column="id"
                orderBy={tableFilters.orderBy}
                orderDirection={tableFilters.orderDirection}
              />
            )}
          </th>
        )}
        {columns.includes('view') && (
          <th
            onClick={() => updateOrdering('id')}
            style={{ cursor: includeOrdering ? 'pointer' : 'default' }}
          >
            <span className="ms-2">ID </span>
          </th>
        )}
        {columns.includes('processing_modal') && (
          <th
            onClick={() => updateOrdering('id')}
            style={{ cursor: includeOrdering ? 'pointer' : 'default' }}
          >
            ID{' '}
            {includeOrdering && (
              <SortArrow
                column="id"
                orderBy={tableFilters.orderBy}
                orderDirection={tableFilters.orderDirection}
              />
            )}
          </th>
        )}
        {columns.includes('report_analytics_count') && (
          <th
            onClick={() => updateOrdering('report_analytics_count')}
            style={{
              cursor: includeOrdering ? 'pointer' : 'default',
              width: '8%'
            }}
          >
            Flags
            {includeOrdering && (
              <SortArrow
                column="report_analytics_count"
                orderBy={tableFilters.orderBy}
                orderDirection={tableFilters.orderDirection}
              />
            )}
          </th>
        )}
        {columns.includes('risk_score') && (
          <th
            onClick={() => updateOrdering('risk_score')}
            style={{
              width: '8%'
            }}
          >
            Risk
          </th>
        )}
        {columns.includes('analytics_count') && (
          <th
            onClick={() => updateOrdering('analytics_count')}
            style={{
              cursor: includeOrdering ? 'pointer' : 'default',
              width: '8%'
            }}
          >
            Flags
            {includeOrdering && (
              <SortArrow
                column="analytics_count"
                orderBy={tableFilters.orderBy}
                orderDirection={tableFilters.orderDirection}
              />
            )}
          </th>
        )}
        {columns.includes('single-status') && (
          <th
            style={{ cursor: includeOrdering ? 'pointer' : 'default' }}
            className="text-center"
          >
            Overall
          </th>
        )}
        {columns.includes('testing-status') && (
          <th
            style={{
              cursor: includeOrdering ? 'pointer' : 'default',
              width: '18%'
            }}
            className="text-center"
          >
            Attributes
          </th>
        )}
        {columns.includes('status') && (
          <th
            style={{
              cursor: includeOrdering ? 'pointer' : 'default',
              width: '20%'
            }}
            className="text-center"
          >
            Transaction Status
          </th>
        )}
        {columns.includes('transaction_on') && (
          <th
            onClick={() => updateOrdering('transaction_on')}
            style={{ cursor: includeOrdering ? 'pointer' : 'default' }}
          >
            Date{' '}
            {includeOrdering && (
              <SortArrow
                column="transaction_on"
                orderBy={tableFilters.orderBy}
                orderDirection={tableFilters.orderDirection}
              />
            )}
          </th>
        )}
        {columns.includes('line_amount') && (
          <th
            onClick={() => updateOrdering('line_amount')}
            style={{ cursor: includeOrdering ? 'pointer' : 'default' }}
          >
            Amount{' '}
            {includeOrdering && (
              <SortArrow
                column="line_amount"
                orderBy={tableFilters.orderBy}
                orderDirection={tableFilters.orderDirection}
              />
            )}
          </th>
        )}
        {columns.includes('vendor') && (
          <th
            onClick={() => updateOrdering('vendors.name')}
            style={{ cursor: includeOrdering ? 'pointer' : 'default' }}
          >
            Vendor{' '}
            {includeOrdering && (
              <SortArrow
                column="vendors.name"
                orderBy={tableFilters.orderBy}
                orderDirection={tableFilters.orderDirection}
              />
            )}
          </th>
        )}
        {columns.includes('vendor_name') && <th>Vendor </th>}
        {columns.includes('employee') && <th>Employee </th>}
        {columns.includes('employee_name') && <th>Employee </th>}
        {columns.includes('cleared') && <th></th>}
      </tr>
    </thead>
  )
}

TableHeader.propTypes = {
  tableFilters: PropTypes.shape({
    orderBy: PropTypes.string,
    orderDirection: PropTypes.string
  }),
  setTableFilters: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired
}

export default TableHeader
