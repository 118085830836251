import React from 'react'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { useUpdateDashboardEmployee } from 'api/hooks/use-dashboard-employees'
import { useQueryClient } from 'react-query'

function UpdateEmployee({ toggleIncludeExclude, employee, dashboardId }) {
  const queryClient = useQueryClient()
  const { mutate } = useUpdateDashboardEmployee({
    params: {
      dashboard_employee_id: employee.dashboard_employee_id,
      options: employee.options
    },
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(`dashboard/${dashboardId}`)
      }
    }
  })

  return (
    <ButtonGroup>
      <Button
        size="sm"
        variant={employee.options === 'include' ? 'primary' : 'light'}
        onClick={() => {
          toggleIncludeExclude(employee, 'include')
          mutate()
      }}
      >
        Include
      </Button>
      <Button
        size="sm"
        variant={employee.options === 'exclude' ? 'primary' : 'light'}
        onClick={() => {
            toggleIncludeExclude(employee, 'exclude')
            mutate()
          }}
      >
        Exclude
      </Button>
    </ButtonGroup>
  )
}

export default UpdateEmployee