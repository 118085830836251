import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import useRiskOverallFrauds from 'api/hooks/use-risk-overall-frauds'
import moment from 'moment'

function RiskLineTransparent({ data }) {
  const { isSuccess, isLoading, data: fraudRisk } = useRiskOverallFrauds({})

  if (isLoading) {
    return <span></span>
  }

  const chartData = fraudRisk.map((s) => {
    return [moment(s[0]).valueOf(), parseFloat(s[1])]
  })

  const options = {
    chart: {
      type: 'areaspline',
      backgroundColor: null,
      height: '35%'
    },
    title: {
      text: ''
    },
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    xAxis: {
      visible: false,
      type: 'datetime',
      labels: {
        formatter: function () {
          return Highcharts.dateFormat('%b %d', this.value)
        }
      }
    },
    yAxis: {
      visible: false
    },
    tooltip: {
      headerFormat: '',
      formatter: function () {
        return (
          this.y +
          ' high risk transactions on ' +
          moment(this.x).format('MMM Do')
        )
      }
    },
    plotOptions: {
      areaspline: {
        lineColor: '#27AB83',
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 1,
          states: {
            hover: {
              enabled: true
            }
          }
        }
      }
    },
    series: [
      {
        name: '',
        data: chartData,
        color: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, '#3f9379'],
            [0.8, '#1E2E48'],
            [1, '#1E2E48']
          ]
        }
      }
    ]
  }

  if (isSuccess) {
    return <HighchartsReact highcharts={Highcharts} options={options} />
  }
}

export default RiskLineTransparent
