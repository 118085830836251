import React from 'react'
import Analytic from './analytics/show.jsx'
import { Route, Routes, Navigate, useParams } from 'react-router-dom'
import AnalyticsTable from './analytics/index.jsx'
import Transaction from './transactions/show.jsx'
import Dashboards from './dashboard/index.jsx'
import Dashboard from './dashboard/show.jsx'
import Employees from './employees/index.jsx'
import Employee from './employees/show.jsx'
import Vendors from './vendors/index.jsx'
import Vendor from './vendors/show.jsx'
import Reports from './reports/index.jsx'
import Report from './reports/show/index.jsx'
import NewReport from './reports/new.jsx'
import Settings from './settings/index.jsx'
import Moles from './moles/index.jsx'
import NewMole from './moles/new.jsx'
import EditMole from 'moles/edit-modal.jsx'
import MoleShow from './moles/show.jsx'
import Workflow from './workflow/index.jsx'
import WorkflowShow from './workflow/show.jsx'
import Saved from './saved/index.jsx'
import Export from './exports/index'
import RiskAssessment from './risk-assessment/index.jsx'
import Transactions from './transactions/index.jsx'

function RedirectWithParam() {
  const { id } = useParams()
  return <Navigate replace to={`/samples/${id}`} />
}

function AuthenticatedUser() {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/risk" />} />
      <Route path="/risk" element={<RiskAssessment />} />
      <Route path="/dashboards" element={<Dashboards />} />
      <Route path="/risk-assessment" element={<RiskAssessment />} />
      <Route path="/vendors" exact={true} element={<Vendors />} />
      <Route path="/vendors/:id" exact={true} element={<Vendor />} />
      <Route path="/transactions/:id" element={<Transaction />} />
      <Route path="/explore" element={<Transactions />} />
      <Route path="/dashboard" element={<Dashboards />} />
      <Route path="/dashboard/:id" element={<Dashboard />} />
      <Route path="/employees" exact={true} element={<Employees />} />
      <Route path="/employees/:id" element={<Employee />} />
      <Route path="/samples" exact={true} element={<Reports />} />
      <Route path="/samples/new" exact={true} element={<NewReport />} />
      <Route path="/samples/:id" element={<Report />} />
      <Route path="/analytics" exact={true} element={<AnalyticsTable />} />
      <Route path="/analytics/:id" element={<Analytic />} />
      <Route path="/monitoring" element={<Moles />} exact={true} />
      <Route path="/monitoring/:id" element={<MoleShow />} exact={true} />
      <Route path="/monitoring-new" element={<NewMole />} exact={true} />
      <Route path="/monitoring/:id/edit" element={<EditMole />} exact={true} />
      <Route path="/saved" element={<Saved />} exact={true} />
      <Route path="/settings" element={<Settings />} exact={true} />
      <Route path="/exports" element={<Export />} exact={true} />
      <Route path="/workflow" element={<Workflow />} exact={true} />
      <Route path="/workflow/:id" element={<WorkflowShow />} />
      {/* Old report routes */}
      <Route path="/reports" element={<Navigate replace to="/samples" />} />
      <Route path="/reports/:id" element={<RedirectWithParam />} />
      <Route
        path="/reports/new"
        element={<Navigate replace to="/samples/new" />}
      />
    </Routes>
  )
}

export default AuthenticatedUser
