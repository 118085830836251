import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'

const getReports = async (token, params) => {
  const url = 'audit_reports'

  const data = await apiGet({ url, params, token })
  return data
}

export default function useReports({ params, options }) {
  const token = useContext(AuthContext)
  const queryFn = () => getReports(token, params)
  const queryKey = ['reports', params]

  return useQuery(queryKey, queryFn, {
    ...options
  })
}

const getReport = async (token, id) => {
  const url = `audit_reports/${id}`

  const data = await apiGet({ url, token })
  return data
}

export function useReport({ id, options }) {
  const token = useContext(AuthContext)
  const queryFn = () => getReport(token, id)
  const queryKey = `reports/${id}`

  return useQuery(queryKey, queryFn, {
    ...options
  })
}
