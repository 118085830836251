import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useConflictOptions from 'api/hooks/use-conflicts'
import Select, { components } from 'react-select'

function ConflictDropdown({ setFilterParams, filterParams }) {
  const [selected, setSelected] = useState('')
  const { isSuccess, data: conflicts } = useConflictOptions({})

  const { Option } = components

  const CustomOption = (props) => (
    <Option {...props}>
      <span className="me-1">{props.data.label}</span>
    </Option>
  )
  const SingleValue = ({ children, ...props }) => (
    <React.Fragment>
      <components.SingleValue {...props}>
        <span className="me-1">{props.data.label}</span>
        {children}
      </components.SingleValue>
    </React.Fragment>
  )

  const options = isSuccess
    ? conflicts.data.map((obj) => ({
        label: obj['name'],
        value: obj['id'],
        description: obj['description']
      }))
    : []

  return (
    <>
      {isSuccess && (
        <Select
          options={options}
          value={selected}
          isClearable={true}
          onChange={(val) => {
            setSelected(val)
            setFilterParams({ ...filterParams, conflict_id: val?.value || '' })
          }}
          id="conflict-selector"
          isOptionDisabled={(option) => option.disabled}
          components={{ SingleValue, Option: CustomOption }}
        />
      )}
    </>
  )
}

ConflictDropdown.propTypes = {
  setFilterParams: PropTypes.func.isRequired,
  filterParams: PropTypes.object.isRequired
}

export default ConflictDropdown
