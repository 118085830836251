import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useRoles from 'api/hooks/use-roles'
import Select, { components } from 'react-select'

function RoleDropdown({ setFilterParams, filterParams }) {
  const [selected, setSelected] = useState('')
  const { isSuccess, data: roles } = useRoles({})

  const { Option } = components

  const CustomOption = (props) => (
    <Option {...props}>
      <span className="me-1">{props.data.label}</span>
    </Option>
  )
  const SingleValue = ({ children, ...props }) => (
    <React.Fragment>
      <components.SingleValue {...props}>
        {children}
      </components.SingleValue>
    </React.Fragment>
  )

  const options = isSuccess
    ? roles.map((obj) => ({
        label: obj['name'],
        value: obj['id']
      }))
    : []

  return (
    <Select
      options={options}
      styles={{ zIndex: 100 }}
      value={selected}
      isClearable={true}
      onChange={(val) => {
        setSelected(val)
        setFilterParams({ ...filterParams, role_id: val?.value || '' })
      }}
      id="role-selector"
      isOptionDisabled={(option) => option.disabled}
      components={{ SingleValue, Option: CustomOption }}
    />
  )
}

RoleDropdown.propTypes = {
  setFilterParams: PropTypes.func.isRequired,
  filterParams: PropTypes.object.isRequired
}

export default RoleDropdown
