import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import Col from 'react-bootstrap/Col'
import SectionDropdown from 'shared/filters/section-dropdown'
import EmployeeFilter from './employee'
import VendorFilter from '../filters/vendor'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTimes,
  faDotCircle,
  faRetweet,
  faExclamation
} from '@fortawesome/free-solid-svg-icons'
import AnalyticsFilter from './analytics'
import ExternalId from './external-id'
import TagDropdown from 'shared/filters/tag-dropdown'
import MoreFilters from './more-filters'
import FlagCount from 'shared/filters/flag-count'
import AccountsFilter from 'shared/filters/accounts'
import UserContext from 'context/user-context'

function TableFilters({
  filters = [],
  tableFilters,
  setTableFilters,
  defaultTableFilters
}) {
  const { has_allocations } = useContext(UserContext)
  const [activeOverlay, setActiveOverlay] = useState('')
  /* TODO: Add ability to pass in which filters to show */
  const [isFiltered, setIsFiltered] = useState(false)
  const [mouseOver, setMouseOver] = useState('')

  useEffect(() => {
    const { open, unviewedOnly, inProgress, exception } = tableFilters
    if (
      (unviewedOnly || open || inProgress || exception) &&
      filters.length > 0
    ) {
      setIsFiltered(true)
    } else {
      setIsFiltered(false)
    }
  }, [tableFilters, filters.length])

  return (
    <React.Fragment>
      {filters.includes('section') && (
        <Col sm="auto">
          <SectionDropdown
            params={tableFilters}
            setParams={setTableFilters}
            hasAllOption={true}
          />
        </Col>
      )}
      {filters.includes('external-id') && (
        <Col sm="auto">
          <ExternalId params={tableFilters} setParams={setTableFilters} />
        </Col>
      )}
      {filters.includes('department') && <></>}
      {filters.includes('employee') && (
        <Col sm="auto">
          <EmployeeFilter
            filterParams={tableFilters}
            setFilterParams={setTableFilters}
            activeOverlay={activeOverlay}
            setActiveOverlay={setActiveOverlay}
          />
        </Col>
      )}
      {filters.includes('vendor') && (
        <Col sm="auto">
          <VendorFilter
            filterParams={tableFilters}
            setFilterParams={setTableFilters}
            activeOverlay={activeOverlay}
            setActiveOverlay={setActiveOverlay}
          />
        </Col>
      )}
      {filters.includes('analytics') && (
        <Col sm="auto">
          <AnalyticsFilter
            filterParams={tableFilters}
            setFilterParams={setTableFilters}
            activeOverlay={activeOverlay}
            setActiveOverlay={setActiveOverlay}
          />
        </Col>
      )}
      {filters.includes('account') && has_allocations && (
        <Col sm="auto">
          <AccountsFilter
            params={tableFilters}
            setParams={setTableFilters}
            setIsFilterOpen={setActiveOverlay}
            withTitle={false}
            buttonSize='md'
          />
        </Col>
      )}
      {filters.includes('flag-count') && (
        <Col sm="auto">
          <FlagCount params={tableFilters} setParams={setTableFilters} />
        </Col>
      )}
      {filters.includes('tags') && (
        <Col sm="1">
          <TagDropdown params={tableFilters} setParams={setTableFilters} />
        </Col>
      )}
      {filters.includes('more-filters') && (
        <Col sm="auto">
          <MoreFilters
            setActiveOverlay={setActiveOverlay}
            filterParams={tableFilters}
            setFilterParams={setTableFilters}
            enabledFilters={filters}
          />
        </Col>
      )}
      {filters.includes('open') && (
        <Col sm="auto">
          <Button
            variant={mouseOver === 'open' ? 'light' : 'white'}
            onMouseEnter={() => setMouseOver('open')}
            onMouseLeave={() => setMouseOver('')}
            className={tableFilters.open ? 'border-dark' : 'fw-light'}
            onClick={() =>
              setTableFilters({
                ...tableFilters,
                open: !tableFilters.open
              })
            }
          >
            <FontAwesomeIcon
              icon={faDotCircle}
              className={tableFilters.open ? 'text-primary' : 'text-muted'}
            />{' '}
            Open
          </Button>
        </Col>
      )}
      {filters.includes('in-progress') && (
        <Col sm="auto">
          <Button
            variant={mouseOver === 'inProgress' ? 'light' : 'white'}
            onMouseEnter={() => setMouseOver('inProgress')}
            onMouseLeave={() => setMouseOver('')}
            className={tableFilters.inProgress ? 'border-dark' : 'fw-light'}
            onClick={() =>
              setTableFilters({
                ...tableFilters,
                inProgress: !tableFilters.inProgress
              })
            }
          >
            <FontAwesomeIcon
              icon={faRetweet}
              className={
                tableFilters.inProgress ? 'text-warning' : 'text-muted'
              }
            />{' '}
            In Progress
          </Button>
        </Col>
      )}
      {filters.includes('exception') && (
        <Col sm="auto">
          <Button
            variant={mouseOver === 'exception' ? 'light' : 'white'}
            onMouseEnter={() => setMouseOver('exception')}
            onMouseLeave={() => setMouseOver('')}
            className={tableFilters.exceptions ? 'border-dark' : 'fw-light'}
            onClick={() =>
              setTableFilters({
                ...tableFilters,
                exception: !tableFilters.exception
              })
            }
          >
            <FontAwesomeIcon
              icon={faExclamation}
              className={tableFilters.exception ? 'text-danger' : 'text-muted'}
            />{' '}
            Exceptions
          </Button>
        </Col>
      )}

      <Col>
        {/* TODO: This causes bug on Audit Report page */}
        {false && isFiltered && (
          <Button
            variant="link"
            onClick={() => setTableFilters(defaultTableFilters)}
          >
            <FontAwesomeIcon icon={faTimes} /> Clear
          </Button>
        )}
      </Col>
    </React.Fragment>
  )
}

TableFilters.propTypes = {
  tableFilters: PropTypes.shape({
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    orderBy: PropTypes.string.isRequired,
    orderDirection: PropTypes.string.isRequired,
    vendors: PropTypes.array.isRequired,
    employees: PropTypes.array.isRequired,
    unviewedOnly: PropTypes.bool.isRequired,
    open: PropTypes.string.isRequired,
    inProgress: PropTypes.string.isRequired,
    exceptions: PropTypes.string.isRequired
  }),
  setTableFilters: PropTypes.func.isRequired,
  defaultTableFilters: PropTypes.object.isRequired
}

export default TableFilters
