import React, { useState, useContext } from 'react'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Page from 'layout/page'
import PageTitle from 'layout/page-title'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faShieldAlt,
  faSearch,
  faArrowCircleRight,
  faFlag
} from '@fortawesome/free-solid-svg-icons'
import RiskLineTransparent from 'risk-assessment/risk-line-transparent'
import UserContext from 'context/user-context'
import { useDashboards } from 'api/hooks/use-dashboards'
import TableLoading from 'shared/tables/table-loading'
import NewView from 'dashboard/new'
import { useNavigate } from 'react-router-dom'

function Dashboard() {
  const navigate = useNavigate()
  const { mole_count, report_count } = useContext(UserContext)
  const [hoverCard, setHoverCard] = useState('')

  const { data: dashboards, isSuccess, isLoading } = useDashboards({})

  return (
    <Page title="Views">
      <PageTitle />
      <Row>
        <Col>
          <Card className="bg-primary text-light rounded-3 h-100">
            <Card.Body>
              <RiskLineTransparent />
              <small className="fw-bolder">
                <FontAwesomeIcon icon={faFlag} className="me-2" /> High Risk
                Transactions
                <span className="float-end fw-light">30 Days</span>
              </small>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card
            className={`rounded-3 h-100 ${
              hoverCard === 'monitoring' ? 'bg-light' : ''
            }`}
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(`/monitoring`)}
            onMouseOver={() => setHoverCard('monitoring')}
            onMouseLeave={() => setHoverCard('')}
          >
            <Card.Body>
              <h3>{mole_count}</h3>
              <>
                Analytics Monitored
                <div className="fw-light text-muted small">
                  View and Setup Monitors
                </div>
              </>
            </Card.Body>
            <Card.Body className="bg-primary text-light rounded-bottom">
              <span className="fw-normal">
                <FontAwesomeIcon icon={faShieldAlt} className="me-2" /> Monitor
              </span>
              <FontAwesomeIcon
                icon={faArrowCircleRight}
                className="float-end mt-1"
              />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card
            className={`rounded-3 h-100 ${
              hoverCard === 'reports' ? 'bg-light' : ''
            }`}
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(`/samples`)}
            onMouseOver={() => setHoverCard('reports')}
            onMouseLeave={() => setHoverCard('')}
          >
            <Card.Body>
              <h3>{report_count}</h3>
              <>
                Reports Scoped
                <div className="fw-light text-muted small">
                  Plan and Run Reports
                </div>
              </>
            </Card.Body>
            <Card.Body className="bg-primary text-light rounded-bottom">
              <span className="fw-normal">
                <FontAwesomeIcon icon={faSearch} className="me-2" /> Reports
                <FontAwesomeIcon
                  icon={faArrowCircleRight}
                  className="float-end mt-1"
                />
              </span>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col>
          <NewView />
        </Col>
      </Row>

      <Row className="mt-2 pb-4">
        <Col>
          <Table
            responsive
            hover
            className="border shadow-sm mt-2 bg-white"
            size="sm"
          >
            <thead>
              <tr>
                <th>View Name</th>
                <th>Section</th>
                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && <TableLoading columns={2} />}
              {isSuccess && dashboards.length === 0 && (
                <tr>
                  <td colSpan="3" className="text-center py-4">
                    <div className="mb-2 h6">No Views Created</div>
                    <div className="text-muted fw-lighter mb-3">
                      Get started by creating a new view
                    </div>
                    <NewView />
                  </td>
                </tr>
              )}

              {isSuccess &&
                dashboards.map((dashboard) => (
                  <tr
                    key={dashboard.id}
                    onClick={() => navigate(`/dashboard/${dashboard.id}`)}
                    style={{ cursor: 'pointer' }}
                  >
                    <td>{dashboard.name}</td>
                    <td>{dashboard.section_name}</td>
                    <td>{dashboard.user_full_name}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Page>
  )
}

export default Dashboard
