import React from 'react'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import Badge from 'react-bootstrap/Badge'
import TableError from 'shared/tables/table-error'
import HighRiskPermissionBadge from '../badges/high-risk-permission'
import InternalConflict from 'duties/badges/internal-conflict'
import ConflictBadge from 'duties/badges/conflict'

function RolesTab({ employee, isError }) {
  return (
    <Card className="border">
      <Card.Header className="bg-white">
        <div className="text-info">Roles</div>
      </Card.Header>
      <Card.Body>
        <Table responsive size="sm">
          <thead>
            <tr>
              <th colSpan={2}>Role</th>
              <th colSpan={2} className="text-center">
                Conflicts
              </th>
              <th colSpan={3} className="text-center">
                Permissions
              </th>
            </tr>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th className="border-start ps-4">Internal to Role</th>
              <th className="border-end">Creating Conflict?</th>
              <th>High Risk</th>
              <th>Super User</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {employee.roles.map((r) => (
              <tr key={`role-row-${r.id}`}>
                <td>
                  <a href={`/roles/${r.id}`} target="_blank" rel="noreferrer">
                    {r.name}
                  </a>
                  <div className="fw-light text-muted">{r.caption}</div>
                </td>
                <td>
                  {r.status ? (
                    <Badge
                      pill
                      className="bg-light text-success mx-1"
                      variant="light"
                    >
                      {' '}
                      Active
                    </Badge>
                  ) : (
                    <Badge
                      pill
                      className="bg-light text-info mx-1"
                      variant="light"
                    >
                      Inactive
                    </Badge>
                  )}
                </td>

                <td className='text-center border-start'>
                  {r.internal_conflicts_count > 0 && (
                    <InternalConflict
                      count={r.internal_conflicts_count}
                      textDisplay="Internal"
                    />
                  )}
                </td>

                <td className='text-center'>
                  {employee.role_ids_causing_conflicts.includes(r.id) && (
                    <ConflictBadge text=" Yes" />
                  )}
                </td>

                <td className='border-start text-center'>
                  {r.high_risk_permissions_count > 0 && (
                    <HighRiskPermissionBadge
                      count={r.high_risk_permissions_count}
                    />
                  )}
                </td>
                <td className="text-muted text-center">{r.superuser_permissions_count}</td>
                <td className="text-muted text-center">{r.permissions_count}</td>
              </tr>
            ))}
            {isError && <TableError columns={7} />}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

export default RolesTab
