import React, { useState } from 'react'
import useTimePeriods from 'api/hooks/use-time-periods'
import TransactionTable from 'transactions/table'
import Button from 'react-bootstrap/Button'

function TopTransactions({ params, sectionUnit, sectionName, dashboardId }) {
  const [showCleared, setShowCleared] = useState(false)
  const timePeriodParams = {
    time_period: params.time_period
  }
  const { isSuccess: isTimePeriodSuccess, data: timePeriods } = useTimePeriods({
    params: timePeriodParams,
    options: {}
  })

  const startOn = isTimePeriodSuccess ? timePeriods.start_date : ''
  const endOn = isTimePeriodSuccess ? timePeriods.end_date : ''
  const queryParams = {
    perPage: 10,
    orderBy: 'risk_score',
    order: 'desc',
    section_id: params.section_id,
    startOn: startOn,
    endOn: endOn,
    minimumAmount: params.minimum_amount,
    maximumAmount: params.maximum_amount,
    showCleared: showCleared,
    vendors: params.vendors.filter((v) => v.options === 'include'),
    excludedVendors: params.vendors.filter((v) => v.options === 'exclude'),
    employees: params.employees.filter((e) => e.options === 'include'),
    excludedEmployees: params.employees.filter((e) => e.options === 'exclude'),
    analytics: params.analytics,
    riskScore: params.risk_threshold,
    org_ids: params.orgs,
    object_ids: params.objects,
    project_ids: params.projects,
    seg_one_ids: params.segOnes,
    seg_two_ids: params.segTwos,
    seg_three_ids: params.segThrees,
    seg_four_ids: params.segFours,
    seg_five_ids: params.segFives,
    seg_six_ids: params.segSixes,
    seg_seven_ids: params.segSevens,
    seg_eight_ids: params.segEights
  }

  const columns = () => {
    if (sectionName === 'General Ledger') {
      return ['cleared', 'view', 'employee_name', 'line_amount', 'risk_score']
    } else if (sectionName === 'Vendor') {
      return ['cleared', 'view', 'vendor_name', 'employee_name', 'risk_score']
    } else {
      return ['cleared', 'view', 'vendor_name', 'line_amount', 'risk_score']
    }
  }

  return (
    // Create a four column table with the following headers:
    // Invoice Number, Vendor, Amount, Risk Score
    <div
      className="bg-white border border-1 rounded"
      style={{ borderColor: '#D2D2D2' }}
    >
      <div className="fw-bold my-2 p-2 ms-2">
        {sectionUnit}s Above Risk Threshold
        <Button
          className="bg-white btn-link text-info float-end"
          size="sm"
          style={{ border: '1px solid #ccc' }}
          variant="info"
          onClick={() => setShowCleared(!showCleared)}
        >
          {showCleared ? 'Show Processed' : 'Hide Processed'}
        </Button>
      </div>
      {isTimePeriodSuccess && queryParams.section_id.length > 0 && (
        <TransactionTable
          filtersEnabled={false}
          downloadButton={false}
          sectionId={queryParams.section_id}
          orderBy={queryParams.orderBy}
          perPage={queryParams.perPage}
          startOn={queryParams.startOn}
          endOn={queryParams.endOn}
          vendors={queryParams.vendors}
          minimumAmount={queryParams.minimumAmount}
          maximumAmount={queryParams.maximumAmount}
          showCleared={queryParams.showCleared}
          excludedVendors={queryParams.excludedVendors}
          analytic={queryParams.analytics}
          employees={queryParams.employees}
          excludedEmployees={queryParams.excludedEmployees}
          orgs={queryParams.org_ids}
          objects={queryParams.object_ids}
          projects={queryParams.project_ids}
          segOnes={queryParams.seg_one_ids}
          segTwos={queryParams.seg_two_ids}
          segThrees={queryParams.seg_three_ids}
          segFours={queryParams.seg_four_ids}
          segFives={queryParams.seg_five_ids}
          segSixes={queryParams.seg_six_ids}
          segSevens={queryParams.seg_seven_ids}
          segEights={queryParams.seg_eight_ids}
          riskScore={queryParams.riskScore}
          source={`${sectionName} - ${sectionUnit}s`}
          columns={columns()}
          footerEnabled={false}
          title={''}
          dashboardId={dashboardId}
        />
      )}
    </div>
  )
}

export default TopTransactions
