import React, { useContext } from 'react'
import Page from 'layout/page'
import PageTitle from 'layout/page-title'
import TransactionTable from 'transactions/table/index'
import UserContext from 'context/user-context'

function SavedTransactions() {
  const { id } = useContext(UserContext)
  const columns = [
    'save',
    'id',
    'analytics_count',
    'transaction_on',
    'line_amount',
    'vendor',
    'employee'
  ]

  return (
    <Page title="Saved">
      <PageTitle />
      <TransactionTable
        filters={[
          'section',
          'department',
          'employee',
          'vendor',
          'amount',
          'date'
        ]}
        orderBy={'analytics_count'}
        source="Saved"
        columns={columns}
        title={'Saved'}
        downloadButton={true}
        saved={id}
      />
    </Page>
  )
}

export default SavedTransactions
