import React from 'react'

function TerminatedToggle({ params, setParams }) {
  return (
    <div className="form-check form-switch mt-1">
      <input
        className="form-check-input"
        type="checkbox"
        id="terminated"
        checked={params.terminated}
        onChange={() => setParams({ ...params, terminated: !params.terminated })}
      />
      <label className="form-check-label" htmlFor="terminated">
        Active Terminated Users
      </label>
    </div>
  )
}

export default TerminatedToggle