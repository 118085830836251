import { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Page from 'layout/page'
import PageTitle from 'layout/page-title'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import TopTransactions from './top-transactions'
import RiskBreakdown from './risk-breakdown'
import OverTime from './over-time'
import { DefaultError } from 'shared/default-error'
import { ErrorBoundary } from '@sentry/react'
import useSections from 'api/hooks/use-sections'
import List from './list'
import DashboardTitle from './title'
import { useDashboard } from 'api/hooks/use-dashboards'
import DashboardFilters from './filters'
import DashboardStats from './stats'
import DashboardPopulation from './population'
import TimeframeSelect from './filters/timeframe-select'
import { split } from 'lodash'
import RiskScoreAdjustment from './filters/risk-score-adjustment'
import { useUpdateDashboard } from 'api/hooks/use-dashboards'

function Dashboard() {
  const path = window.location.pathname
  const dashboardUrlId = split(path, '/')[2]
  const [dashboardId, setDashboardId] = useState(dashboardUrlId)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const { isSuccess: isSectionSuccess, data: sections } = useSections({
    options: {}
  })

  const defaultSection = isSectionSuccess && sections[0]
  const options = {
    enabled: !!dashboardId && !isFilterOpen
  }
  const { data: dashboard, isSuccess: isDashboardLoaded } = useDashboard({
    id: dashboardId,
    options
  })
  const defaultParams = {
    name: defaultSection.name || '',
    time_period: '30',
    risk_threshold: 0,
    section_id: defaultSection.id || ''
  }
  const [params, setParams] = useState({
    ...defaultParams,
    dashboardId: dashboardId,
    minimum_amount: '',
    maximum_amount: '',
    vendors: [],
    employees: [],
    analytics: [],
    account_search: '',
    orgs: [],
    objects: [],
    projects: [],
    segOnes: [],
    segTwos: [],
    segThrees: [],
    segFours: [],
    segFives: [],
    segSixes: [],
    segSevens: [],
    segEights: []
  })

  const sectionName = isSectionSuccess
    ? sections.find((s) => s.id === params.section_id)?.name
    : ''
  const sectionUnit = isSectionSuccess
    ? sections.find((s) => s.id === params.section_id)?.unit
    : ''

  useEffect(() => {
    if (isDashboardLoaded && !!dashboardId) {
      setParams((params) => ({
        ...params,
        name: dashboard?.name,
        time_period: dashboard?.time_period,
        risk_threshold: parseFloat(dashboard?.risk_threshold),
        section_id: dashboard?.section_id,
        dashboardId: dashboardId,
        minimum_amount: dashboard?.minimum_amount || '',
        maximum_amount: dashboard?.maximum_amount || '',
        vendors: dashboard?.vendors || [],
        employees: dashboard?.employees || [],
        analytics: dashboard?.analytics || [],
        orgs: dashboard?.account_search?.orgs || [],
        objects: dashboard?.account_search?.objects || [],
        projects: dashboard?.account_search?.projects || [],
        segOnes: dashboard?.account_search?.seg_ones || [],
        segTwos: dashboard?.account_search?.seg_twos || [],
        segThrees: dashboard?.account_search?.seg_threes || [],
        segFours: dashboard?.account_search?.seg_fours || [],
        segFives: dashboard?.account_search?.seg_fives || [],
        segSixes: dashboard?.account_search?.seg_sixes || [],
        segSevens: dashboard?.account_search?.seg_sevens || [],
        segEights: dashboard?.account_search?.seg_eights || []
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDashboardLoaded, dashboardId, setParams])

  const dashboardSettings = {
    risk_threshold: params.risk_threshold,
    time_period: params.time_period
  }
  const { mutate } = useUpdateDashboard({
    params: dashboardSettings,
    id: dashboardId
  })

  useEffect(() => {
    if (isDashboardLoaded && !!dashboardId) {
      mutate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.risk_threshold, params.time_period])

  return (
    <Page
      title={
        <Row>
          <Col sm="auto">
            {isDashboardLoaded && (
              <DashboardTitle params={params} setParams={setParams} />
            )}
          </Col>
          <Col sm="auto">
            {isDashboardLoaded && (
              <List
                params={params}
                setParams={setParams}
                setDashboardId={setDashboardId}
              />
            )}
          </Col>
          <Col sm="auto">
            <TimeframeSelect
              params={params}
              setParams={setParams}
              hideLabel={true}
            />
          </Col>
          <Col sm="auto">
            <RiskScoreAdjustment
              params={params}
              setParams={setParams}
              hideLabel={true}
            />
          </Col>
        </Row>
      }
      pageTitle={`View - ${isDashboardLoaded ? dashboard?.name : ''}`}
    >
      <PageTitle />
      <Row className="mb-4">
        <Col>
          <Alert variant="warning" className="mb-0 py-1">
            🚧 <strong>Beta:</strong> This feature is still in development.
            Please use it and let us know what you think!
          </Alert>
        </Col>
      </Row>

      <Row className="my-4">
        <Col>
          <ErrorBoundary
            fallback={DefaultError}
            beforeCapture={(scope) => {
              scope.setTag('component', 'DashboardPopulation')
            }}
          >
            <DashboardPopulation
              params={params}
              setParams={setParams}
              sectionUnit={sectionUnit}
              sectionName={sectionName}
              isFilterOpen={isFilterOpen}
            />
          </ErrorBoundary>
        </Col>
      </Row>

      <Row>
        <Col>
          <DashboardFilters
            params={params}
            setParams={setParams}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            sectionName={sectionName}
          />
        </Col>
      </Row>
      {isSectionSuccess && (
        <>
          <Row className="mt-4">
            <Col>
              <Row>
                <ErrorBoundary
                  fallback={DefaultError}
                  beforeCapture={(scope) => {
                    scope.setTag('component', 'StatCard')
                  }}
                >
                  <DashboardStats
                    params={params}
                    setParams={setParams}
                    sectionUnit={sectionUnit}
                    isFilterOpen={isFilterOpen}
                    sectionName={sectionName}
                  />
                </ErrorBoundary>
              </Row>

              <ErrorBoundary
                fallback={DefaultError}
                beforeCapture={(scope) => {
                  scope.setTag('component', 'RiskBreakdown')
                }}
              >
                <RiskBreakdown
                  params={params}
                  isFilterOpen={isFilterOpen}
                  sectionUnit={sectionUnit}
                  sectionName={sectionName}
                />
              </ErrorBoundary>
            </Col>
            <Col>
              <ErrorBoundary
                fallback={DefaultError}
                beforeCapture={(scope) => {
                  scope.setTag('component', 'TopTransactions')
                }}
              >
                <TopTransactions
                  params={params}
                  sectionUnit={sectionUnit}
                  sectionName={sectionName}
                  dashboardId={dashboardId}
                />
              </ErrorBoundary>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <ErrorBoundary
                fallback={DefaultError}
                beforeCapture={(scope) => {
                  scope.setTag('component', 'OverTime')
                }}
              >
                {sectionName !== '' && (
                  <OverTime
                    params={params}
                    sectionUnit={sectionUnit}
                    sectionName={sectionName}
                  />
                )}
              </ErrorBoundary>
            </Col>
          </Row>
        </>
      )}
    </Page>
  )
}

export default Dashboard
