import React from 'react'
import {
  faChartLine,
  faFileAlt,
  faBuilding,
  faFlag,
  faIdCard,
  faSearchDollar,
  faBookmark,
  faDownload,
  faShieldAlt,
  faHome,
  faPeopleArrows
} from '@fortawesome/free-solid-svg-icons'
import SidebarLink from './sidebar-link'

function Sidebar() {
  return (
    <nav className="col-md-2 d-none d-md-block sidebar bg-white">
      <div className="sidebar-sticky">
        <ul className="nav flex-column mt-2">
          <SidebarLink
            path={'/risk'}
            url="risk"
            title="Risk Assessment"
            icon={faHome}
          />

          <SidebarLink
            path={'samples'}
            url="samples"
            title="Samples"
            icon={faFileAlt}
          />

          <SidebarLink
            path={'monitoring'}
            url="monitoring"
            title="Monitoring"
            icon={faShieldAlt}
          />

          <SidebarLink
            path={'analytics'}
            url="analytics"
            title="Analytics"
            icon={faFlag}
          />

          <SidebarLink
            path={'vendors'}
            url="vendors"
            title="Vendors"
            icon={faBuilding}
          />

          <SidebarLink
            path={'employees'}
            url="employees"
            title="Employees"
            icon={faIdCard}
          />

          <SidebarLink
            path={'explore'}
            url="explore"
            title="Explore"
            icon={faSearchDollar}
          />

          <SidebarLink
            path={'dashboards'}
            url="dashboards"
            title="Views"
            icon={faChartLine}
          />

          <SidebarLink
            path={'workflow'}
            url={'workflow'}
            title={'Workflow'}
            icon={faPeopleArrows}
          />

          <SidebarLink
            path={'saved'}
            url={'saved'}
            title={'Saved'}
            icon={faBookmark}
          />

          <SidebarLink
            path={'exports'}
            url={'exports'}
            title={'Exports'}
            icon={faDownload}
          />
        </ul>
      </div>
    </nav>
  )
}

export default Sidebar
