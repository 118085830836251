import React from 'react'
import Form from 'react-bootstrap/Form'

function RiskScoreAdjustment({ params, setParams, hideLabel=false }) {
  return (
    <div className="align-items-center">
      {!hideLabel && (
        <label className="mr-2 fw-light text-muted">
          <small>Set Risk Threshold</small>
        </label>
      )}
      <Form.Control
        as="select"
        className="d-inline-block"
        size="sm"
        value={params.risk_threshold}
        onChange={(e) =>
          setParams({ ...params, risk_threshold: e.target.value })
        }
      >
        <option value={0}>Risk Threshold: 0+</option>
        <option value={0.5}>Risk Threshold: 0.5+</option>
        <option value={1}>Risk Threshold: 1+</option>
        <option value={1.5}>Risk Threshold: 1.5+</option>
        <option value={2}>Risk Threshold: 2+</option>
        <option value={2.5}>Risk Threshold: 2.5+</option>
        <option value={3}>Risk Threshold: 3+</option>
        <option value={3.5}>Risk Threshold: 3.5+</option>
        <option value={4}>Risk Threshold: 4+</option>
        <option value={4.5}>Risk Threshold: 4.5+</option>
        <option value={5}>Risk Threshold: 5+</option>
        <option value={5.5}>Risk Threshold: 5.5+</option>
        <option value={6}>Risk Threshold: 6+</option>
        <option value={6.5}>Risk Threshold: 6.5+</option>
        <option value={7}>Risk Threshold: 7+</option>
      </Form.Control>
    </div>
  )
}

export default RiskScoreAdjustment
