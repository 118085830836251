import React, { useState } from 'react'
import RiskChart from 'dashboard/risk-chart'
import { capitalize } from 'lodash'
import Card from 'react-bootstrap/Card'
import useDashboardGroupingCounts from 'api/hooks/use-dashboard-grouping-counts'
import Form from 'react-bootstrap/Form'
import CenteredSpinner from 'shared/centered-spinner'

function RiskBreakdown({ params, isFilterOpen, sectionUnit, sectionName }) {
  const [calcType, setCalcType] = useState('count')
  const [groupBy, setGroupBy] = useState('analytic')
  const queryParams = {
    groupBy: groupBy,
    calcType: calcType,
    time_period: params.time_period,
    section_id: params.section_id,
    risk_threshold: params.risk_threshold,
    minimum_amount: params.minimum_amount,
    maximum_amount: params.maximum_amount,
    vendors: params.vendors.filter((v) => v.options === 'include').map((v) => v.id).join('+'),
    excluded_vendors: params.vendors.filter((v) => v.options === 'exclude').map((v) => v.id).join('+'),
    employees: params.employees.filter((e) => e.options === 'include').map((e) => e.id).join('+'),
    excluded_employees: params.employees.filter((e) => e.options === 'exclude').map((e) => e.id).join('+'),
    analytics: params.analytics.map(a => a.id).join('+'),
    org_ids: params.orgs.join('+'),
    object_ids: params.objects.join('+'),
    project_ids: params.projects.join('+'),
    seg_one_ids: params.segOnes.join('+'),
    seg_two_ids: params.segTwos.join('+'),
    seg_three_ids: params.segThrees.join('+'),
    seg_four_ids: params.segFours.join('+'),
    seg_five_ids: params.segFives.join('+'),
    seg_six_ids: params.segSixes.join('+'),
    seg_seven_ids: params.segSevens.join('+'),
    seg_eight_ids: params.segEights.join('+')
  }
  const options = {
    enabled: !isFilterOpen
  }
  const { isLoading, isSuccess, data } = useDashboardGroupingCounts({ params: queryParams, options })

  const groupingTypes =
    sectionName !== 'Vendor'
      ? [
          { value: 'count', label: `${sectionUnit} Count` },
          { value: 'amount', label: `${sectionUnit} Amount` },
          { value: 'risky_flag_count', label: 'Risky Flag Count' },
          { value: 'risky_amount', label: 'Risky Amount' }
        ]
      : [
          { value: 'count', label: `${sectionUnit} Count` },
          { value: 'risky_flag_count', label: 'Risky Flag Count' }
        ]

  return (
    <>
      <Card>
        <Card.Header className="bg-white d-flex flex-row">
          <div className="fw-bolder my-auto d-flex">
            <Form.Group>
              <Form.Select
                as="select"
                size="sm"
                value={calcType}
                onChange={(e) => setCalcType(e.target.value)}
              >
                {groupingTypes.map((t) => (
                  <option value={t.value} key={`option-${t.value}`}>
                    {t.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>

          <div className="fw-bolder my-auto d-flex">
            <span className="mx-3">grouped by</span>
          </div>
          <div className="fw-bolder my-2 d-flex">
            <Form.Group>
              <Form.Select
                as="select"
                size="sm"
                value={groupBy}
                onChange={(e) => setGroupBy(e.target.value)}
              >
                {['analytic', 'vendor', 'employee'].map((t) => (
                  <option value={t} key={t}>
                    {capitalize(t)}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>
        </Card.Header>
        <Card.Body>
          {isLoading && <CenteredSpinner />}
          {isSuccess && (
            <RiskChart
              chartType={capitalize(groupBy)}
              calcType={calcType}
              counts={data}
            />
          )}
        </Card.Body>
      </Card>
    </>
  )
}

export default RiskBreakdown
