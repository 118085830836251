import React, { useState } from 'react'
import Page from 'layout/page'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import PageTitle from 'layout/page-title'
import Card from 'react-bootstrap/Card'
import TransactionTable from 'transactions/table'
import { split, isEmpty } from 'lodash'
import Spinner from 'react-bootstrap/Spinner'
import ReportProgress from './features'
import ReportBadge from './badge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import Alert from 'react-bootstrap/Alert'
import { noDigitMoney } from 'helpers/utils'
import { useReport } from 'api/hooks/use-reports'
import AnalyticsShield from 'shared/analytics-shield'
import ShareButton from '../share-button'
import Summary from './progress/summary'
import { DefaultError } from 'shared/default-error'
import { ErrorBoundary } from '@sentry/react'

function Report() {
  const path = window.location.pathname
  const reportId = split(path, '/')[2]
  const [activeTab, setActiveTab] = useState('transactions')
  const columns = [
    'processing_modal',
    'report_analytics_count',
    'single-status',
    'testing-status',
    'transaction_on',
    'line_amount',
    'employee',
    'vendor'
  ]

  const options = {
    refetchInterval: (data) => {
      return data?.status === 'generating' ? 1000 : false
    }
  }
  const {
    isSuccess,
    isLoading,
    data: report
  } = useReport({ id: reportId, options })

  const crumbs = [{ name: 'Samples', url: 'samples' }]

  return (
    <div style={{ backgroundColor: '#f5f5f5' }}>
      <Page
        title={isSuccess ? report.name : ''}
        pageTitle={isSuccess ? `Sample - ${report.name}` : ''}
        subtitle={
          isSuccess && (
            <span className="mt-2">
              <span className="me-2">
                <AnalyticsShield
                  count={report.analytics_count}
                  analytics={report.analytics}
                />
                <ShareButton report={report} />
              </span>
              <ReportBadge text={`Module: ${report.section?.name}`} clickable={false} />
              <ReportBadge text={`${report.start_on} - ${report.end_on}`} clickable={false} />
              <ReportBadge text={`${report.employees.length} Employees`} clickable={false} />
              <ReportBadge text={`${report.vendors.length} Vendors`} clickable={false} />
              <ReportBadge
                text={`Amount: 
                    ${noDigitMoney(report.minimum_amount)}
                    - 
                    ${
                      report.maximum_amount
                        ? noDigitMoney(report.maximum_amount)
                        : 'No Max'
                    }`}
                clickable={false}
              />
              {<span className="mt-2"></span>}
            </span>
          )
        }
      >
        {isLoading && (
          <div className="h-100">
            <Spinner animation="grow" variant="warning" />
          </div>
        )}
        {isSuccess && !isEmpty(report) && (
          <>
            <PageTitle breadcrumbs={crumbs} />
            <Button
              variant={`${
                activeTab === 'transactions' ? 'primary' : 'outline-primary'
              }`}
              size="sm"
              onClick={() => setActiveTab('transactions')}
            >
              Transactions
            </Button>

            <Button
              variant={`${
                activeTab === 'attributes' ? 'primary' : 'outline-primary'
              }`}
              size="sm"
              className="ms-2"
              onClick={() => setActiveTab('attributes')}
            >
              Testing Attributes
            </Button>

            {activeTab === 'attributes' && (
              <Row className="mt-2">
                <Col sm={12} md={12}>
                  <ErrorBoundary
                    fallback={DefaultError}
                    beforeCapture={(scope) => {
                      scope.setTag('component', 'ReportProgress')
                    }}
                  >
                    <ReportProgress report={report} />
                  </ErrorBoundary>
                </Col>
              </Row>
            )}

            {activeTab === 'transactions' && (
              <Row className="mt-4">
                {isSuccess && report.features.length === 0 && (
                  <Col sm={12}>
                    <Alert variant="warning">
                      <FontAwesomeIcon
                        className="text-warning"
                        icon={faExclamationTriangle}
                      />{' '}
                      No Additional Testing Attributes created
                      <div className="float-end">
                        <Button
                          variant="light"
                          className="bg-white text-primary"
                          size="sm"
                          onClick={() => setActiveTab('attributes')}
                        >
                          Add Testing Attribute
                        </Button>
                      </div>
                    </Alert>
                  </Col>
                )}
                <Col>
                  <h4 className="text-muted">Transaction Sample</h4>
                  <Card className="shadow-sm">
                    <Card.Body>
                      {isSuccess && <Summary report={report} />}
                    </Card.Body>
                    <Card.Body>
                      {isSuccess && report.status === 'generating' && (
                        <React.Fragment>
                          <Spinner
                            animation="border"
                            variant="primary"
                            size="sm"
                          />{' '}
                          Generating report...
                        </React.Fragment>
                      )}

                      {isSuccess && report.status === 'available' && (
                        <TransactionTable
                          filters={[
                            'employee',
                            'vendor',
                            'open',
                            'in-progress',
                            'exception'
                          ]}
                          title=""
                          source={report.name}
                          report={report}
                          orderBy={'report_analytics_count'}
                          columns={columns}
                          auditReportId={reportId}
                        />
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )}

            <Row className="my-4 py-4"></Row>
            <Row className="my-4 py-4"></Row>
            <Row className="my-4 py-4"></Row>
          </>
        )}
      </Page>
    </div>
  )
}

export default Report
