import React from 'react'
import { useUpdateDashboard } from 'api/hooks/use-dashboards'
import { useQueryClient } from 'react-query'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"

function DeleteButton({ dashboardId }) {
  const queryClient = useQueryClient()
  const params = {
    is_deleted: true
  }
  const { mutate } = useUpdateDashboard({
    params,
    id: dashboardId,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries('dashboards')     
      }
    }
  })

  return (
    <button
      className="btn btn-sm btn-link text-danger"
      onClick={() => {    
        mutate()
      }}
    >
      <FontAwesomeIcon icon={faTrash} />
    </button>
  )
}

export default DeleteButton