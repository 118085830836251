import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import AmountFilter from './amount'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useUpdateDashboard } from 'api/hooks/use-dashboards'

function MoreFilters({ params, setParams, setIsFilterOpen }) {
  const [show, setShow] = useState(false)
  const handleClose = () => {
    setIsFilterOpen(false)
    setShow(false)
  }
  const handleShow = () => {
    setIsFilterOpen(true)
    setShow(true)
  }

  const amountParams = {
    minimum_amount: params.minimum_amount,
    maximum_amount: params.maximum_amount
  }

  const { mutate } = useUpdateDashboard({
    params: amountParams,
    id: params.dashboardId
  })

  useEffect(() => {
    mutate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.minimum_amount, params.maximum_amount])

  const isFiltered = params.minimum_amount || params.maximum_amount

  return (
    <React.Fragment>
      <label className="mr-2 fw-light text-muted">
        <small>More Filters</small>
      </label>
      <div>
        <Button
          className={`bg-white ${isFiltered ? 'text-primary' : 'text-info'}`}
          onClick={handleShow}
          size="sm"
          style={{ border: isFiltered ? '' : '1px solid #ccc' }}
          variant={isFiltered ? 'outline-primary' : 'outline-light'}
        >
          <FontAwesomeIcon icon={faFilter} /> Filters
        </Button>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>More Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <AmountFilter
                params={params}
                setParams={setParams}
                accessorKey={'minimum_amount'}
                title="Minimum"
              />
            </Col>
            <Col>
              <AmountFilter
                params={params}
                setParams={setParams}
                accessorKey={'maximum_amount'}
                title="Maximum"
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default MoreFilters
