import React from 'react'
import { ListGroup } from 'react-bootstrap'
import { useCreateDashboardVendor } from 'api/hooks/use-dashboard-vendors'
import { useQueryClient } from 'react-query'

function VendorItem({ vendor, params, dashboardId, addOrRemoveVendor }) {
  const queryClient = useQueryClient()
  const queryParams = {
    vendor_id: vendor.id,
    dashboard_id: params.dashboardId,
    options: vendor.options
  }
  const { mutate } = useCreateDashboardVendor({
    params: queryParams,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries(`dashboard/${dashboardId}`)
      }
    }
  })

  return (
    <ListGroup.Item
      action
      key={`vendor-option-${vendor.id}`}
      active={params.vendors.includes(vendor.id) ? true : false}
      className={params.vendors.includes(vendor.id) ? 'py-2 bg-light' : 'py-2'}
      onClick={() => {
        addOrRemoveVendor(vendor)
        mutate()
      }}
    >
      {vendor.name} <small className="text-muted">(ID: {vendor.external_id})</small>
    </ListGroup.Item>
  )
}

export default VendorItem