import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { formatDateSlash } from 'helpers/datetime'
import QuickLookDrawer from 'transactions/drawer'
import ProcessingAuditReportDrawer from 'transactions/processing-audit-report-drawer'
import ProcessingMoleDrawer from 'transactions/processing-mole-drawer'
import Button from 'react-bootstrap/Button'
import { twoDigitMoney } from 'helpers/utils'
import FlagCount from 'shared/flag-count'
import DestroyBookmark from 'shared/destroy-bookmark'
import CreateBookmark from 'shared/create-bookmark'
import MultiReviewStatusColumn from './multi-review-status-column'
import ReviewStatusColumn from './review-status-column'
import NewReviewBadge from 'shared/badges/new-review-badge'
import VendorChange from 'shared/vendor-change-badge'
import TestingStatus from './testing-status'
import { useNavigate } from 'react-router-dom'
import RiskBadge from 'shared/badges/risk-badge'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { truncate, isNull } from 'lodash'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

function TableRow({
  transaction,
  transactions,
  columns,
  auditReportId,
  moleId,
  dashboardId
}) {
  const [showQuickLook, setShowQuickLook] = useState(false)
  const [showProcessing, setShowProcessing] = useState(false)
  const navigate = useNavigate()

  const isVendorChange = transaction.section_name === 'Vendor'

  const displayKey = (transaction) => {
    if (
      transaction.section_name === 'PCard' ||
      transaction.section_name === 'General Ledger'
    ){
      return truncate(transaction.display_key, { length: 15 })
    } else if (transaction.section_name === 'Purchasing') {
      return isNull(transaction.display_key_two) ? truncate(`RQ: ${transaction.display_key}`, { length: 15 }) : truncate(`PO: ${transaction.display_key_two}`, { length: 15 })
    }
    else {
      return truncate(transaction.display_key_two, { length: 15 })
    }
  }


  return (
    <tr className={isVendorChange ? 'table-light' : ''}>
      {columns.includes('save') && (
        <td className="align-middle">
          <div className="ms-2">
            {transaction.saved_transaction?.id ? (
              <DestroyBookmark transaction={transaction} />
            ) : (
              <CreateBookmark transaction={transaction} />
            )}
          </div>
        </td>
      )}
      {columns.includes('id') && (
        <td className="align-middle">
          <Button variant="link" onClick={() => setShowQuickLook(true)}>
            {transaction.section_name === 'PCard'
              ? transaction.display_key
              : transaction.section_display_key + ' ' + transaction.display_key}
          </Button>
        </td>
      )}
      {columns.includes('view') && (
        <td className="align-middle">
          <Button variant="link" onClick={() => setShowQuickLook(true)}>
            {displayKey(transaction)}
          </Button>
        </td>
      )}
      {columns.includes('processing_modal') && (
        <td className="align-middle">
          <Button variant="link" onClick={() => setShowProcessing(true)}>
            {transaction.external_id}
          </Button>
          {transaction?.is_viewed === false && (
            <NewReviewBadge showCount={false} />
          )}
        </td>
      )}
      {columns.includes('risk_score') && (
        <td className="text-center align-middle">
          <RiskBadge count={transaction.risk_score} showCount={true} />
        </td>
      )}
      {columns.includes('analytics_count') && (
        <td className="text-center align-middle">
          <FlagCount count={transaction.analytics_count} />
        </td>
      )}
      {columns.includes('report_analytics_count') && (
        <td className="text-center align-middle">
          <FlagCount count={transaction.report_analytics_count} />
        </td>
      )}
      {columns.includes('status') && (
        <td className="align-middle text-center">
          <MultiReviewStatusColumn reviewSummary={transaction.review_summary} />
        </td>
      )}
      {columns.includes('single-status') && (
        <td className="align-middle text-center">
          <ReviewStatusColumn transaction={transaction} />
        </td>
      )}
      {columns.includes('testing-status') && (
        <td className="align-middle text-center">
          <TestingStatus featureSummary={transaction.feature_summary} />
        </td>
      )}
      {columns.includes('transaction_on') && (
        <td className="align-middle text-muted">
          {formatDateSlash(transaction.transaction_on)}
        </td>
      )}
      {columns.includes('line_amount') && (
        <td className="align-middle">
          {transaction.section_name === 'Vendor' ? (
            <VendorChange />
          ) : (
            twoDigitMoney(transaction.line_amount)
          )}
        </td>
      )}
      {columns.includes('vendor') && (
        <td className="align-middle text-muted fw-light">
          <a
            href={`/vendors/${transaction.vendor_id}`}
            onClick={(e) => {
              e.preventDefault()
              navigate(`/vendors/${transaction.vendor_id}`)
            }}
          >
            {truncate(transaction.vendor_name, { length: 20 })}
          </a>
        </td>
      )}
      {columns.includes('vendor_name') && (
        <td className="align-middle text-muted fw-light">
          {transaction.vendor_name}
        </td>
      )}
      {columns.includes('employee') && (
        <td className="align-middle text-muted fw-light">
          <a
            href={`/employees/${transaction.employee_id}`}
            onClick={(e) => {
              e.preventDefault()
              navigate(`/employees/${transaction.employee_id}`)
            }}
          >
            {transaction.employee_external_id}
          </a>
        </td>
      )}
      {columns.includes('employee_name') && (
        <td className="align-middle text-muted fw-light">
          {transaction.employee_external_id}
        </td>
      )}
      {showQuickLook && (
        <QuickLookDrawer
          transactions={transactions}
          transactionId={transaction.id}
          show={showQuickLook}
          setShow={setShowQuickLook}
          dashboardId={dashboardId}
        />
      )}
      {showProcessing && (
        <React.Fragment>
          {auditReportId ? (
            <ProcessingAuditReportDrawer
              transactions={transactions}
              transactionId={transaction.id}
              externalId={transaction.external_id}
              auditReportId={auditReportId}
              show={showProcessing}
              setShow={setShowProcessing}
            />
          ) : (
            <ProcessingMoleDrawer
              transactions={transactions}
              transactionId={transaction.id}
              externalId={transaction.external_id}
              moleId={moleId}
              show={showProcessing}
              setShow={setShowProcessing}
            />
          )}
        </React.Fragment>
      )}
      {columns.includes('cleared') && (
        <td className="align-middle">
          {transaction.cleared_status === 'No Exception' && (
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="tooltip-top">
                  <strong>Cleared with No Exception</strong>
                </Tooltip>
              }
            >
              <span>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-primary"
                  style={{ cursor: 'pointer' }}
                />
              </span>
            </OverlayTrigger>
          )}
          {transaction.cleared_status === 'Exception' && (
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip>
                  <strong>Cleared with Exception</strong>
                </Tooltip>
              }
            >
              <span>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className="text-danger"
                  style={{ cursor: 'pointer' }}
                />
              </span>
            </OverlayTrigger>
          )}
        </td>
      )}
    </tr>
  )
}

TableRow.propTypes = {
  transaction: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired
}

export default TableRow
