import * as React from 'react'
import Badge from 'react-bootstrap/Badge'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

function RiskyBadge({
  count,
  title,
  dollarValue
}) {
  if (count === undefined || count === null || count === 0) {
    return <React.Fragment></React.Fragment>
  } else {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip>Uncleared {title}</Tooltip>}
      >
        <Badge
          pill
          className={'text-center text-danger high-risk badge-danger fw-normal'}
          style={{ cursor: 'pointer' }}
          data-testid={'risky-badge'}
        >
          {dollarValue
            ? '$' + Math.round(count).toLocaleString()
            : count + ' Uncleared'}
        </Badge>
      </OverlayTrigger>
    )
  }
}

export default RiskyBadge