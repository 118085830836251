import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import MoreFilters from './more-filters'
import VendorFilter from './vendor-filter'
import EmployeeFilter from './employee-filter'
import AccountsFilter from '../../shared/filters/accounts'
import AnalyticsFilters from './analytics'

function DashboardFilters({
  params,
  setParams,
  isFilterOpen,
  setIsFilterOpen,
  sectionName
}) {
  return (
    <Card>
      <Card.Body className="pt-2">
        <Row>
          <Col sm="auto">
            <VendorFilter
              params={params}
              setParams={setParams}
              isFilterOpen={isFilterOpen}
              setIsFilterOpen={setIsFilterOpen}
            />
          </Col>
          <Col sm="auto">
            <EmployeeFilter
              params={params}
              setParams={setParams}
              isFilterOpen={isFilterOpen}
              setIsFilterOpen={setIsFilterOpen}
            />
          </Col>
          {['General Ledger', 'Accounts Payable'].includes(sectionName) && (
            <Col sm="auto">
              <AccountsFilter
                params={params}
                setParams={setParams}
                isFilterOpen={isFilterOpen}
                setIsFilterOpen={setIsFilterOpen}
              />
            </Col>
          )}
          <Col sm="auto">
            <AnalyticsFilters
              params={params}
              setParams={setParams}
              isFilterOpen={isFilterOpen}
              setIsFilterOpen={setIsFilterOpen}
            />
          </Col>
          {sectionName !== 'Vendor' && (
            <Col sm="auto">
              <MoreFilters
                params={params}
                setParams={setParams}
                isFilterOpen={isFilterOpen}
                setIsFilterOpen={setIsFilterOpen}
              />
            </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  )
}

export default DashboardFilters
