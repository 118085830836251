import { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsExporting from 'highcharts/modules/exporting'
import Card from 'react-bootstrap/Card'
import useDashboardOverTimes from 'api/hooks/use-dashboard-over-times'
import Form from 'react-bootstrap/Form'
import CenteredSpinner from 'shared/centered-spinner'

HighchartsExporting(Highcharts)

Highcharts.setOptions({
  lang: {
    thousandsSep: ','
  }
})

function OverTime({ params, isFilterOpen, sectionUnit, sectionName }) {
  const [currentTab, setCurrentTab] = useState('count')
  const [highRisk, setHighRisk] = useState([])
  const [lowRisk, setLowRisk] = useState([])
  const queryParams = {
    time_period: params.time_period,
    groupBy: currentTab,
    risk_threshold: params.risk_threshold,
    section_id: params.section_id,
    minimum_amount: params.minimum_amount,
    maximum_amount: params.maximum_amount,
    vendors: params.vendors
      .filter((v) => v.options === 'include')
      .map((v) => v.id)
      .join('+'),
    excluded_vendors: params.vendors
      .filter((v) => v.options === 'exclude')
      .map((v) => v.id)
      .join('+'),
    employees: params.employees
      .filter((e) => e.options === 'include')
      .map((e) => e.id)
      .join('+'),
    excluded_employees: params.employees
      .filter((e) => e.options === 'exclude')
      .map((e) => e.id)
      .join('+'),
    analytics: params.analytics.map((a) => a.id).join('+'),
    org_ids: params.orgs.join('+'),
    object_ids: params.objects.join('+'),
    project_ids: params.projects.join('+'),
    seg_one_ids: params.segOnes.join('+'),
    seg_two_ids: params.segTwos.join('+'),
    seg_three_ids: params.segThrees.join('+'),
    seg_four_ids: params.segFours.join('+'),
    seg_five_ids: params.segFives.join('+'),
    seg_six_ids: params.segSixes.join('+'),
    seg_seven_ids: params.segSevens.join('+'),
    seg_eight_ids: params.segEights.join('+')
  }
  const queryOptions = {
    enabled: !isFilterOpen
  }
  const { data, isSuccess, isLoading } = useDashboardOverTimes({ params: queryParams, options: queryOptions })
  const isAmount = currentTab === 'amount'
  
  useEffect(() => {
    if (isSuccess && currentTab === 'amount') {
      setHighRisk(data.map((d) => Math.round(parseFloat(d.high_risk_amount))))
      setLowRisk(data.map((d) => Math.round(parseFloat(d.low_risk_amount))))
    } else if (isSuccess && currentTab === 'count') { 
      setHighRisk(data.map((d) => d.high_risk_count))
      setLowRisk(data.map((d) => d.low_risk_count))
    }
  }, [currentTab, data, isSuccess])

  const options = {
    chart: {
      type: 'column',
      marginBottom: 100
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: isSuccess ? data.map((d) => d.transaction_on) : []
    },
    yAxis: {
      min: 0,
      title: {
        text: currentTab
      },
      stackLabels: {
        enabled: true
      }
    },
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      y: 15,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || 'white',
      borderColor: '#CCC',
      borderWidth: 1,
      shadow: false
    },
    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      shared: true,
      valueDecimals: 0,
      pointFormat: isAmount
        // eslint-disable-next-line no-template-curly-in-string
        ? '{series.name}: ${point.y:,.f}<br/>'
        : '{series.name}:  {point.y:,.f}<br/>'
    },
    plotOptions: {
      column: {
        animation: false,
        stacking: 'normal'
      },
      lang: {
        thousandsSep: ','
      }
    },
    series: [
      {
        name: 'Above Risk Threshold',
        data: highRisk,
        color: '#E77152'
      },
      {
        name: 'Below Risk Threshold',
        data: lowRisk,
        color: '#cccccc'
      }
    ]
  }

  const filterTypes =
    sectionName === 'Vendor'
      ? [
          { value: 'count', label: `${sectionUnit} Count` }
        ]
      : [
          { value: 'count', label: `${sectionUnit} Count` },
          { value: 'amount', label: `${sectionUnit} Amount` }
        ]

  return (
    <Card>
      <Card.Header className="bg-white d-flex flex-row">
        <div className="fw-bolder my-auto d-flex">Risk Over Time </div>
        <div className="fw-bolder my-2 d-flex ms-4">
          <Form.Group>
            <Form.Select
              as="select"
              size="sm"
              value={currentTab}
              onChange={(e) => setCurrentTab(e.target.value)}
            >
              {filterTypes.map((t) => (
                <option value={t.value} key={`option-${t.value}`}>
                  {t.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="my-4">
          {isLoading && <CenteredSpinner />}
          {isSuccess && (
            <HighchartsReact highcharts={Highcharts} options={options} />
          )}
        </div>
      </Card.Body>
    </Card>
  )
}

export default OverTime
