import React, { useState, useContext } from 'react'
import Page from 'layout/page'
import PageTitle from 'layout/page-title'
import TransactionTable from 'transactions/table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import TransactionsByMonth from 'shared/transactions-by-month'
import AnalyticsByMonthContainer from './AnalyticsByMonthContainer'
import AnalyticCountChart from 'shared/analytic-count-chart'
import CumulativeSpend from 'shared/cumulative-spend'
import { split } from 'lodash'
import { getMax, noDigitMoney } from 'helpers/utils'
import RiskBadge from 'shared/badges/risk-badge'
import Stat from 'shared/stat'
import { useVendor } from 'api/hooks/use-vendors'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCreditCard, faClone } from '@fortawesome/free-regular-svg-icons'
import EmptyPage from 'layout/empty-page'
import StatusBadge from 'shared/badges/status-badge'
import VendorAttribute from 'shared/badges/vendor-attribute'
import UserContext from 'context/user-context'

function Vendor() {
  const path = window.location.pathname
  const vendorId = split(path, '/')[2]
  const [activeTab, setActiveTab] = useState('flags')
  const { municipality } = useContext(UserContext)

  const params = { id: vendorId }
  const { isSuccess, isLoading, data: vendor } = useVendor({ params })

  const crumbs = [{ name: 'Vendors', url: 'vendors' }]

  const chartDescription = () => {
    if (activeTab === 'flags') {
      return 'Analytic Flags Over Time'
    } else if (activeTab === 'transactions') {
      return 'Transactions Over Time'
    } else if (activeTab === 'analytic') {
      return 'Top Analytics Count'
    } else if (activeTab === 'spend') {
      return 'Total Invoice Amount Over Time'
    }
  }

  if (isLoading) {
    return <EmptyPage />
  }

  return (
    <Page
      title={
        <span>
          {vendor.name}
          {vendor.status && (
            <small className="text-muted ms-2">{vendor.status}</small>
          )}

          <span className="lead ms-2">
            <RiskBadge
              showCount={true}
              count={vendor.analytics_per_transaction}
            />
          </span>

          {vendor.is_pcard ? (
            <span className="ms-2">
              <StatusBadge
                title="PCard"
                icon={faCreditCard}
                tooltipText="Employee generated from PCard file"
              />
            </span>
          ) : null}
          {vendor.recent_payment_change && (
            <VendorAttribute
              title="Recent Payment Change"
              tooltipText={`Vendor payment method changed in the last 30 days: ${vendor.last_payment_method_change_on}`}
            />
          )}
          {vendor.stale && (
            <VendorAttribute
              title="Stale"
              tooltipText={`Vendor has not sent invoice or recieved a check in ${municipality.stale_vendor_months} months. Last Invoice: ${vendor.last_invoice_on} Last Check: ${vendor.last_check_on}`}
            />
          )}
          {vendor.po_box && (
            <VendorAttribute
              title="PO Box"
              tooltipText={'Vendor has a PO Box address'}
            />
          )}
          {vendor.one_eight_hundred && (
            <VendorAttribute
              title="1-800"
              tooltipText={'Vendor has an 800 number'}
            />
          )}
          {vendor.is_duplicate_ein && (
            <VendorAttribute
              title={
                <span>
                  <FontAwesomeIcon icon={faClone} /> EIN
                </span>
              }
              tooltipText={`Vendor has a duplicated EIN with another active vendor: ${vendor.duplicate_ein_vendors
                .split('||')
                .join(', ')}`}
            />
          )}
          {vendor.is_duplicate_ein_being_paid && (
            <VendorAttribute
              title={
                <span>
                  <FontAwesomeIcon icon={faClone} /> EIN $
                </span>
              }
              tooltipText={`Vendor has a duplicated EIN with another active vendor: ${vendor.duplicate_ein_being_paid_vendors
                .split('||')
                .join(', ')}`}
            />
          )}
        </span>
      }
      pageTitle={`Vendor - ${vendor.name}`}
    >
      <React.Fragment>
        <Row>
          <Col>
            <PageTitle
              title={<span className="text-primary">{vendor.name}</span>}
              breadcrumbs={crumbs}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col sm={3}>
            <Stat
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              text={Math.round(vendor.analytics_count).toLocaleString()}
              subtext={'Total Flags'}
              name={'flags'}
            />
          </Col>
          <Col sm={3}>
            <Stat
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              text={Math.round(vendor.transactions_count).toLocaleString()}
              subtext={'Total Transactions'}
              name={'transactions'}
            />
          </Col>
          <Col sm={3}>
            <Stat
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              text={getMax(vendor.count_by_analytic)}
              subtext={'Top Analytic'}
              name={'analytic'}
            />
          </Col>
          <Col sm={3}>
            <Stat
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              text={noDigitMoney(vendor.transactions_amount)}
              subtext={'Total Invoiced'}
              name={'spend'}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col sm={12}>
            <Card>
              <Card.Header className="bg-white">
                {chartDescription()}
              </Card.Header>

              <Card.Body>
                {activeTab === 'flags' && (
                  <AnalyticsByMonthContainer vendorId={vendor.id} />
                )}
                {activeTab === 'transactions' && (
                  <TransactionsByMonth
                    countByMonth={vendor.transactions_by_month}
                  />
                )}
                {activeTab === 'analytic' && (
                  <AnalyticCountChart
                    analyticCounts={vendor.count_by_analytic}
                  />
                )}
                {activeTab === 'spend' && (
                  <CumulativeSpend spend={vendor.cumulative_spend} title={''} />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <div className="my-5"></div>

        <Row className="mt-4">
          <Col>
            <h4>Transactions for Vendor</h4>
            <hr />
            {isSuccess && (
              <TransactionTable
                filters={[
                  'external-id',
                  'employee',
                  'amount',
                  'analytics',
                  'date',
                  'flag-count',
                  'more-filters'
                ]}
                vendors={[vendor]}
                source={vendor.name}
                columns={[
                  'save',
                  'id',
                  'risk_score',
                  'analytics_count',
                  'transaction_on',
                  'line_amount',
                  'employee'
                ]}
              />
            )}
          </Col>
        </Row>
      </React.Fragment>
    </Page>
  )
}

export default Vendor
