import moment from 'moment'

export const formatDateTime = (date) => {
  return moment(date).format('MMMM Do YYYY, h:mm:ss a')
}

export const formatDate = (date) => {
  return moment(date).format('MMM Do YYYY')
}

export const formatDateSlash = (date) => {
  return moment(date).format('MM/DD/YYYY')
}

export const formatShortDate = (date) => {
  return moment(date).format('MMM Do, YY')
}

export const datetimeAscending = (dates) => {
  return dates.sort((a, b) => a.date.localeCompare(b.date))
}

export const jsDateToSlash = (date) => {
  const month = date.getMonth() + 1
  const day = date.getDate()
  const year = date.getFullYear()
  return month + '/' + day + '/' + year
}

export const generateRelativeMonths = () => {
  // Array to hold month names
  const monthNamesLong = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const monthNamesShort = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  // Get current date
  const currentDate = new Date()
  let currentMonth = currentDate.getMonth() // Note: January is 0, February is 1, and so on.
  let currentYear = currentDate.getFullYear()

  // Array to store month data
  let monthsArray = []

  // Generate data for the previous 12 months
  for (let i = 0; i < 12; i++) {
    // Insert at the beginning to maintain chronological order
    monthsArray.unshift({
      month_short: monthNamesShort[currentMonth],
      month_long: monthNamesLong[currentMonth],
      year: currentYear.toString()
    })

    // Move to the previous month, adjust year if needed
    currentMonth--
    if (currentMonth < 0) {
      // Adjust for indexing (January is 0)
      currentMonth = 11 // Reset to December
      currentYear-- // Move to the previous year
    }
  }

  return monthsArray
}
