import React, { useContext } from 'react'
import { generateRelativeMonths } from 'helpers/datetime'
import FilterContext from 'context/filters-context'

function TimeframeSelect({ params, setParams, hideLabel = false }) {
  const months = generateRelativeMonths()
  const { filters, setFilters } = useContext(FilterContext)

  const handleChange = (e) => {
    setParams({ ...params, time_period: e.target.value })
    setFilters({ ...filters, time_period: e.target.value })
  }

  return (
    <div className="align-items-center">
      {!hideLabel && (
        <label className="mr-2 fw-light text-muted">
          <small>Timeframe</small>
        </label>
      )}
      <select
        as="select"
        className="form-control form-control-sm form-inline my-0"
        value={params.time_period}
        onChange={(e) => handleChange(e)}
      >
        <option value="30">Past 30 days</option>
        <option value="60">30-60 days</option>
        <option value="90">60-90 days</option>
        <option value="120">90-120 days</option>
        {/* Option Group: Months */}
        <option disabled>──────────</option>
        {months.map((month, index) => (
          <option key={index} value={month.month_short}>
            {month.month_long} ({month.year})
          </option>
        ))}
        <option disabled>──────────</option>
        <option value="LQ1">Q1 (previous)</option>
        <option value="LQ2">Q2 (previous)</option>
        <option value="LQ3">Q3 (previous)</option>
        <option value="LQ4">Q4 (previous)</option>
        <option value="Q1">Q1</option>
        <option value="Q2">Q2</option>
        <option value="Q3">Q3</option>
        <option value="Q4">Q4</option>
        <option disabled>──────────</option>
        <option value="PFY">Previous Fiscal Year</option>
        <option value="FY">Current Fiscal Year</option>
      </select>
    </div>
  )
}

export default TimeframeSelect
