import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'
import UserContext from 'context/user-context'

const getRiskOverallFrauds = async (token, municipality_id, params) => {
  const url = `municipalities/${municipality_id}/risk_overall_frauds`

  const data = await apiGet({ url, token, params })
  return data
}

export default function useRiskOverallFrauds({ params, options }) {
  const token = useContext(AuthContext)
  const { municipality_id } = useContext(UserContext)
  const queryKey = `${municipality_id}/risk-overall-frauds`
  const queryFn = () => getRiskOverallFrauds(token, municipality_id, params)

  return useQuery(queryKey, queryFn, {
    ...options
  })
}
