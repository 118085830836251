import { isEmpty, isNumber, isNaN, isPlainObject, snakeCase, mapValues, mapKeys } from 'lodash'

export const getMax = (object) => {
  let max = Math.max(...Object.values(object))
  return Object.keys(object).filter((key) => object[key] === max)
}

export const calculatePercent = (num, denom) => {
  if (denom === 0 || num === null || denom === null) {
    return 0
  } else {
    return Math.round((100 * num) / denom)
  }
}

export const calculateDifference = (first, second) => {
  if (first === null || second === null) {
    return 0
  } else {
    return (first - second).toFixed(2)
  }
}

export const flagsPerTransaction = (num, denom) => {
  if (denom === 0 || num === null || denom === null) {
    return 0
  } else {
    return Math.round(num / denom)
  }
}

export const noDigitMoney = (money) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  }).format(money)
}

export const twoDigitMoney = (money) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  }).format(money)
}

export const mapObjectsToOptions = (
  arrayOfObj,
  labelKey = 'name',
  valKey = 'id'
) => arrayOfObj.map((obj) => ({ label: obj[labelKey], value: obj[valKey] }))

export const mapObjectToOption = (obj, labelKey = 'name', valKey = 'id') => {
  return { label: obj[labelKey], value: obj[valKey] }
}

export const isBlank = (value) => {
  return (isEmpty(value) && !isNumber(value)) || isNaN(value)
}

export function titleize(sentence) {
  if (!sentence || !sentence.split) return sentence

  const _titleizeWord = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()

  sentence = sentence.replace(/_/g, ' ')
  sentence = sentence.replace('/', ' / ')
  const result = sentence.split(' ').map((word) => _titleizeWord(word))

  return result.join(' ')
}

export function convertKeysToSnakeCase(obj) {
  if (Array.isArray(obj)) {
    // Process arrays recursively
    return obj.map(convertKeysToSnakeCase)
  } else if (isPlainObject(obj)) {
    // Process objects by converting keys and recursively processing values
    return mapValues(
      mapKeys(obj, (value, key) => snakeCase(key)),
      convertKeysToSnakeCase
    )
  }
  // Return the value directly if it's neither an object nor an array
  return obj
}