import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ModulePicker from 'reports/form/module-picker'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Shield from 'assets/Shield/Gold/ThirdLineShieldGold.svg'
import { useCreateDashboard } from 'api/hooks/use-dashboards'
import { useNavigate } from 'react-router-dom'

function NewView() {
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [params, setParams] = useState({})

  const options = {
    onSuccess: (data) => {
      navigate(`/dashboard/${data.id}`)
    }
  }
  const { mutate } = useCreateDashboard({
    params: { ...params, section_id: params.sectionId },
    options
  })

  return (
    <React.Fragment>
      <Button variant="primary" onClick={handleShow}>
        <img src={Shield} style={{ width: 15 }} alt="ThirdLine Shield" /> Create
        View
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Create View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-2">
            <Col>
              <Form.Group>
                <Form.Label>Name of View</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="View Name"
                  onChange={(e) =>
                    setParams({ ...params, name: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="h5 mt-4">Select Module</div>
          <ModulePicker setParams={setParams} params={params} limitModules={['AP', 'GL', 'PO', 'PCard', 'Vendor']} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="white" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => mutate()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default NewView
