import { useContext } from 'react'
import { useQuery } from 'react-query'
import { apiGet } from 'api'
import AuthContext from 'context/auth-context'
import UserContext from 'context/user-context'
import { omit } from 'lodash'

const getDashboardGroupingCounts = async (token, params, municipalityId) => {
  const url = `municipalities/${municipalityId}/dashboard_grouping_counts`
  const data = await apiGet({ url, token, params })
  return data
}

export default function useDashboardGroupingCounts({ params, options }) {
  const { municipality_id } = useContext(UserContext)
  const token = useContext(AuthContext)
  const queryParams = omit(params, ['municipality_id'])
  const queryFn = () => getDashboardGroupingCounts(token, queryParams, municipality_id)
  const queryKey = [`municipalities/${municipality_id}/dashboard_grouping_counts`, queryParams]
  return useQuery(queryKey, queryFn, { ...options })
}
