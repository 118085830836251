import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'

function SidebarLink({ path, url, title, icon }) {
  const [currentPath, setCurrentPath] = useState(window.location.pathname)
  const navigate = useNavigate()

  useEffect(() => {
    if (currentPath.includes('roles')) {
      setCurrentPath('duties')
    }
    if (currentPath.includes('duties')) {
      setCurrentPath('duties')
    }
    if (currentPath.includes('conflicts')) {
      setCurrentPath('duties')
    }
    if (currentPath.includes('permissions')) {
      setCurrentPath('duties')
    }
  }, [currentPath])

  const activeLink = () => {
    if (path === '/') {
      return currentPath === path ? true : false
    } else {
      return currentPath.includes(path)
    }
  }

  return (
    <li
      className={`nav-item ${
        activeLink() ? 'border-right border-primary' : ''
      }`}
      onClick={() => navigate(`/${url}`)}
    >
      <a
        className={`nav-link ${activeLink() ? 'active' : 'fw-light'}`}
        href={`/${path}`}
        onClick={(e) => e.preventDefault()}
      >
        <FontAwesomeIcon
          className={activeLink() ? '' : 'feather'}
          style={{ minWidth: '30' }}
          icon={icon}
        />{' '}
        {title}
      </a>
    </li>
  )
}

export default SidebarLink
