import React from 'react'
import Card from 'react-bootstrap/Card'
import RiskBadge from './risky-badge'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { has } from 'lodash'


function StatCard({ stat }) {
  const displayValue = stat.dollarValue
                ? '$' + Math.round(stat.value).toLocaleString()
                : Math.round(stat.value).toLocaleString()

  const displayTotal = stat.dollarValue
                ? '$' + Math.round(stat.total).toLocaleString()
                : Math.round(stat.total).toLocaleString()


  return (
    <Card>
      <Card.Body>
        <Row>
          <Col sm="auto">
            <h4>{displayValue}</h4>
          </Col>
          <Col>
            {' '}
            <span className="float-end">
              {stat.uncleared > 0 && (
                <RiskBadge
                  count={stat.uncleared}
                  title={stat.title}
                  dollarValue={stat.dollarValue}
                />
              )}
              {has(stat, 'percent') && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      {displayValue} out of {displayTotal}
                    </Tooltip>
                  }
                >
                  <span className="text-sm float-end ms-2 text-muted" style={{ cursor: 'pointer' }}>
                    {Math.round(stat.percent * 10) / 10}%
                  </span>
                </OverlayTrigger>
              )}
            </span>
          </Col>
        </Row>

        <Card.Text className="text-muted">
          {stat.title}

          {/* Make pretty check for all cleared */}
          {/* Use danger icon for Uncleared */}
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default StatCard
