import Form from 'react-bootstrap/Form'

function RecentPaymentChange({ params, setParams }) {
  const checked = params.recentPaymentChange === true
  
  return (
    <>
      <Form.Check
        inline
        checked={checked}
        label="Recent Payment Change"
        name="recent-payment-change"
        type={'checkbox'}
        onChange={() =>
          setParams({
            ...params,
            recentPaymentChange: !params.recentPaymentChange
          })
        }
        id={'recent-payment-change-checkbox'}
      />
      <div className="ms-4 text-muted fw-light">
        Vendor has had a change in payment method in the past 30 days
      </div>
    </>
  )
}

export default RecentPaymentChange
