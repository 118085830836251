import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { useUpdateDashboardVendor } from 'api/hooks/use-dashboard-vendors'
import { useQueryClient } from 'react-query'

function UpdateVendor({ toggleIncludeExclude, vendor, dashboardId }) {
  const queryClient = useQueryClient()
  const [toggle, setToggle] = useState(vendor.options)
  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries(`dashboard/${dashboardId}`)
    }
  }
  const params = {
    dashboard_vendor_id: vendor.dashboard_vendor_id,
    options: toggle
  }
  const { mutate } = useUpdateDashboardVendor({
    params,
    options
  })

  useEffect(() => {
    mutate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle])

  return (
    <ButtonGroup>
      <Button
        size="sm"
        variant={vendor.options === 'include' ? 'primary' : 'light'}
        onClick={() => {
          setToggle('include')
          toggleIncludeExclude(vendor, 'include')
        }}
      >
        Include
      </Button>
      <Button
        size="sm"
        variant={vendor.options === 'exclude' ? 'primary' : 'light'}
        onClick={() => {
          setToggle('exclude')
          toggleIncludeExclude(vendor, 'exclude')
        }}
      >
        Exclude
      </Button>
    </ButtonGroup>
  )
}

export default UpdateVendor
