import React from 'react'
import Card from 'react-bootstrap/Card'
import pCardIcon from 'assets/Icons/analytics-purchasing-card.svg'
import arIcon from 'assets/Icons/analytics-accounts-receivable.svg'
import glIcon from 'assets/Icons/analytics-general-ledger.svg'
import apIcon from 'assets/Icons/analytics-accounts-payable.svg'
import hrIcon from 'assets/Icons/analytics-human-resources.svg'
import payrollIcon from 'assets/Icons/analytics-payroll.svg'
import purchasingIcon from 'assets/Icons/analytics-purchasing.svg'
import sodIcon from 'assets/Icons/analytics-segregation-of-duties.svg'
import teIcon from 'assets/Icons/analytics-travel-entertainment.svg'
import vendorIcon from 'assets/Icons/analytics-vendor.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

function ModuleCardIcon({ section, setParams, params }) {
  const icons = {
    AP: apIcon,
    AR: arIcon,
    GL: glIcon,
    HR: hrIcon,
    PR: payrollIcon,
    PC: pCardIcon,
    PO: purchasingIcon,
    SOD: sodIcon,
    TE: teIcon,
    Vendor: vendorIcon
  }

  const icon = icons[section.abbreviation]
  const active = params.sectionId === section.id

  return (
    <Card
      className={'shadow-sm'}
      style={{
        cursor: 'pointer',
        background: active ? '#f0f5f7' : '',
        border: active ? '1px solid #6ea0b5' : ''
      }}
      onClick={(e) =>
        setParams({
          ...params,
          sectionId: section.id,
          includedAnalytics: []
        })
      }
    >
      <Card.Body>
        {active ? (
          <span>
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="text-primary ms-1"
            />
          </span>
        ) : (
          <span>
            {' '}
            <FontAwesomeIcon icon={faCheckCircle} className="text-white ms-1" />
          </span>
        )}
        <div className="d-flex justify-content-center">
          <img
            src={icon}
            className="img-fluid"
            style={{ height: '35px' }}
            alt={section.name}
          />
        </div>
        <div className="d-flex justify-content-center">
          <Card.Text className="fw-lighter my-2">{section.name}</Card.Text>
        </div>
      </Card.Body>
    </Card>
  )
}

export default ModuleCardIcon
