import React from 'react'
import Page from 'layout/page'
import PageTitle from 'layout/page-title'
import TransactionTable from './table'
import queryString from 'query-string'

function Transactions() {
  const params = queryString.parse(window.location.search)
  const columns = [
    'save',
    'id',
    'risk_score',
    'analytics_count',
    'transaction_on',
    'line_amount',
    'vendor',
    'employee'
  ]

  const queryParams = {
    id:
      params.seg_one_id ||
      params.seg_two_id ||
      params.seg_three_id ||
      params.seg_four_id ||
      params.seg_five_id || ''
  }

  return (
    <Page title={'Explore Transactions'}>
      <PageTitle />
      {(queryParams.id === '') && (
        <TransactionTable
          filters={[
            'section',
            'account',
            'department',
            'employee',
            'vendor',
            'amount',
            'analytics',
            'tags',
            'date',
            'flag-count',
            'more-filters'
          ]}
          orderBy={'analytics_count'}
          includeOrdering={false}
          source="Explore"
          columns={columns}
          sectionId={params['section_id'] || ''}
          tag={params.tag_id || ''}
          analytic={params.analytic_id ? [{ id: params.analytic_id }] : []}
          employees={params.employee_id ? [{ id: params.employee_id }] : []}
          vendors={params.vendor_id ? [{ id: params.vendor_id }] : []}
          orgs={params.org || []}
          objectIds={params.object || []}
          segOneIds={params.seg_one || []}
          segTwoIds={params.seg_two || []}
          segThreeIds={params.seg_three || []}
          segFourIds={params.seg_four || []}
          segFiveIds={params.seg_five || []}
          projectIds={params.project || []}
          disableJump={true}
        />
      )}
    </Page>
  )
}

export default Transactions
