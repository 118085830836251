import React, { useContext } from 'react'
import Table from 'react-bootstrap/Table'
import useTransactionWorkflows from 'api/hooks/use-transaction-workflow'
import { sumBy } from 'lodash'
import moment from 'moment'
import WorkflowActionBadge from './workflow-action-badge'
import UserContext from 'context/user-context'

function Workflow({ transactionId }) {
  const params = {
    transaction_id: transactionId
  }
  const { isSuccess, data } = useTransactionWorkflows({ params })
  const { municipality_name } = useContext(UserContext)

  return (
    <div className='mt-4'>
      {isSuccess && ['Wilmington', 'Tulsa Public Schools', 'College Station'].includes(municipality_name) && data.length > 0 && (
        <>
          <>
            <div>
              <span className="h5">Workflow</span>
            </div>
            <span className="text-muted text-sm fw-light mt-4 me-5">
              Process Time:{' '}
              {moment
                .duration(sumBy(data, 'seconds_between_action'), 'seconds')
                .humanize()}
            </span>
            <span className="text-muted text-sm fw-light ms-4">
              Entered At:{' '}
              {moment(data[0].entered_at, 'YYYY-MM-DD HH:mm:ss').format(
                'YYYY-MM-DD HH:mm:ss'
              )}
            </span>
            <span className="text-muted text-sm fw-light ms-4">
              Last Action At:{' '}
              {moment(
                data[data.length - 1].action_at,
                'YYYY-MM-DD HH:mm:ss'
              ).format('YYYY-MM-DD HH:mm:ss')}
            </span>
          </>
          <div className="my-2"></div>

          <Table className="border border-light my-4" responsive>
            <thead>
              <tr>
                <th>Approver (step)</th>
                <th>Time in Queue</th>
                <th>Action</th>
                <th>Comment</th>
              </tr>
            </thead>
            <tbody>
              {data.map((w) => (
                <tr>
                  <td>
                    {w.approver_external_id}{' '}
                    <small className="text-muted">({w.step_number})</small>
                  </td>
                  <td>
                    <span className="text-muted fw-light">
                      {moment
                        .duration(
                          moment(w.action_at, 'YYYY-MM-DD HH:mm:ss').diff(
                            moment(w.entered_at, 'YYYY-MM-DD HH:mm:ss')
                          )
                        )
                        .humanize()}
                    </span>
                  </td>
                  <td>
                    <WorkflowActionBadge action={w.action} />
                  </td>
                  <td>
                    <span className="text-muted">{w.comment}</span>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={5} className='bg-light text-muted text-center'>
                  Pending Workflow Actions Not Displayed
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
    </div>
  )
}

export default Workflow
